import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { USER_LOGOUT } from "./ActionType";
import userReducer from "./Reducers/userReducer";
import chatReducer from "./Reducers/chatReducer";
import { routes } from "../utils/routes";
import { USER_LOGIN_DATA } from "../utils/constants";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
  ],
};

const appReducer = combineReducers({
  user: userReducer,
  chat: chatReducer,
  // formData: formReducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  // middleware: [thunkMiddleware],
});

export const persistor = persistStore(store);

// const rootReducer = (state, action) => {
//   if (action.type === USER_LOGOUT) {
//     state = undefined;
//     window.localStorage.removeItem(USER_LOGIN_DATA);
//     window.location.href = routes.login;
//   }
//   return appReducer(state, action);
// };
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );
