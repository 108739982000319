import React, { useEffect, useRef, useState } from "react";
import backIcon from "../../assets/BackIcon.png";
import { useNavigate } from "react-router-dom";
import billing_dash from "../../assets/billing_dash.png";
import billing_active from "../../assets/billing_active.png";
import setting_dash from "../../assets/setting_dash.png";
import setting_active from "../../assets/setting_active.png";
import help_dash from "../../assets/help_dash.png";
import help_active from "../../assets/help_active.png";
import employee_dash from "../../assets/employee_dash.png";
import employee_active from "../../assets/employee_active.png";

import "./SecondWelcomeWithCategory.css";
import Home from "../../assets/SVG/Home";
import { USER_LOGIN_DATA } from "../../utils/constants";
import { routes } from "../../utils/routes";
import Popup from "./Popup"; // Import the Popup component
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/AppAction";
import { createBrowserHistory } from "history";
import Modal from "react-modal";
import middleware from "../../middleware";

const customStyles = {
  content: {
    borderRadius: "15px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
function SecondWelcome_Sidebar() {
  const ref = useRef(null);
  const { userData } = useSelector((state) => state?.user);

  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  useEffect(() => {
    // Function to handle popstate event
    const handlePopstate = () => {
      // Get the current pathname from the history object
      const currentPathname = history.location.pathname;
      // Set the activeItem based on the current pathname
      setActiveItem(currentPathname);
    };
    setActiveItem(history.location.pathname);
    // Add event listener for popstate when component mounts
    window.addEventListener("popstate", handlePopstate);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(routes.dashboard);
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State to manage popup visibility
  const navigate = useNavigate();
  const history = createBrowserHistory();

  const handleItemClick = (path) => {
    setActiveItem(path);
    navigate(path);
  };

  const onClickBack = () => {
    setIsPopupVisible(true);
    openModal();
  };
  const logoutt = () => {
    setIsPopupVisible(false);
    localStorage.removeItem(USER_LOGIN_DATA);
    dispatch(logout());
  };

  return (
    <div ref={ref} className="px-2 w-full SecondWelcome_Sidebar_color bg-white">
      <div
        className={`hover:bg-[#e3f3ff] rounded cursor-pointer flex flex-col justify-center items-center py-2 gap-1 ${
          activeItem === routes.dashboard ? "active" : ""
        }`}
        onClick={() => handleItemClick(routes.dashboard)}
      >
        {activeItem === routes.dashboard ? <Home /> : <Home fill="none" />}
        <p
          style={{ fontWeight: activeItem == routes.dashboard ? "700" : "" }}
          className="SecondWelcome_Sidebar_text"
        >
          Dashboard
        </p>
      </div>
      {userData && !userData.is_employee ? (
        <div
          className={`hover:bg-[#e2f2ff] rounded cursor-pointer flex flex-col justify-center items-center py-2 gap-1 ${
            activeItem === routes.dashboard_billing ? "active font-extrabold" : ""
          }`}
          onClick={() => handleItemClick(routes.dashboard_billing)}
        >
          {activeItem === routes.dashboard_billing ? (
            <img src={billing_dash} className="w-7" alt="Billing" />
          ) : (
            <img src={billing_active} className="w-7" alt="Billing" />
          )}
          <p
            style={{
              fontWeight: activeItem == routes.dashboard_billing ? "700" : "",
            }}
            className="SecondWelcome_Sidebar_text text-center"
          >
            Billing
          </p>
        </div>
      ) : null}
      <div
        className={`hover:bg-[#e2f2ff] rounded cursor-pointer flex flex-col justify-center items-center py-2 gap-1 ${
          activeItem === routes.dashboard_setting ? "active" : ""
        }`}
        onClick={() => handleItemClick(routes.dashboard_setting)}
      >
        {activeItem === routes.dashboard_setting ? (
          <img src={setting_active} className="w-6" alt="Setting" />
        ) : (
          <img src={setting_dash} className="w-6" alt="Setting" />
        )}
        <p
          style={{
            fontWeight: activeItem == routes.dashboard_setting ? "700" : "",
          }}
          className="SecondWelcome_Sidebar_text text-center"
        >
          Setting
        </p>
      </div>
      {userData && userData.plan_actions.find((x) => x.action_name == "employee") ? (
        <div
          className={`hover:bg-[#e2f2ff] rounded cursor-pointer flex flex-col justify-center items-center py-2 gap-1 ${
            activeItem === routes.dashboard_employee ? "active" : ""
          }`}
          onClick={() => handleItemClick(routes.dashboard_employee)}
        >
          {activeItem === routes.dashboard_employee ? (
            <img src={employee_active} className="w-7" alt="Employee Active" />
          ) : (
            <img src={employee_dash} className="w-7" alt="Employee" />
          )}
          <p
            style={{
              fontWeight: activeItem == routes.dashboard_employee ? "700" : "",
            }}
            className="SecondWelcome_Sidebar_text text-center"
          >
            Add Employee
          </p>
        </div>
      ) : null}
      <div
        className={`hover:bg-[#e2f2ff] rounded cursor-pointer flex flex-col justify-center items-center py-2 gap-1 ${
          activeItem === routes.dashboard_faq ? "active" : ""
        }`}
        onClick={() => handleItemClick(routes.dashboard_faq)}
      >
        {activeItem === routes.dashboard_faq ? (
          <img src={help_active} className="w-7" alt="FAQ Active" />
        ) : (
          <img src={help_dash} className="w-7" alt="FAQ" />
        )}
        <p
          style={{
            fontWeight: activeItem == routes.dashboard_faq ? "700" : "",
          }}
          className="SecondWelcome_Sidebar_text text-center"
        >
          FAQ & Help
        </p>
      </div>
      <div
        onClick={onClickBack}
        className="hover:bg-[#e2f2ff] h-10 rounded cursor-pointer flex flex-col justify-center items-center exitButton py-2 gap-1"
      >
        <img src={backIcon} className="w-6" alt="Back" />
      </div>
      {isPopupVisible && (
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
          <div className=" w-56 sm:w-60 md:w-96">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)} className="text-blue-400"></h2>
            <div className="pb-6">
              <h1 className="font-semibold text-xl">Logout?</h1>
              <p className="text-xs pt-1">Are you sure to want logout</p>
            </div>
            <div className="w-full flex gap-3 justify-center md:justify-end">
              <button
                onClick={closeModal}
                className="text-sm text-[#525A8F] rounded-full bg-[#F5F9FF] px-4 py-[7px] hover:bg-[#e8f1ff]"
              >
                Cancel
              </button>
              <button
                onClick={logoutt}
                className="text-sm text-white rounded-full bg-[#328BDE] px-8 py-[7px] hover:bg-[#2d7ec9]"
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default middleware(SecondWelcome_Sidebar);
