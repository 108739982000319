import React, { useEffect, useRef, useState } from "react";
import "./opt.css";
import logo from "../../assets/logo.svg";
import forgetPasswordHalfImg from "../../assets/forgetPasswordHalfImg.svg";
import passwordcheck from "../../assets/password-check.svg";
import { useNavigate } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import VerificationInput from "react-verification-code-input";
import { routes } from "../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { validateCode } from "../../hooks/userManagment";
import { loginAction } from "../../redux/AppAction";
import { USER_LOGIN_DATA } from "../../utils/constants";
import Loader from "../../components/loading-spinner/Loader";
import { ErrorCodes } from "../../utils/enums";

export default function Otp() {
  const { userData } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email] = useState(localStorage.getItem("forgetPasswordEmail"));

  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (!otp) setOtpErr(true);
    else {
      navigate(routes.set_new_password);
    }
  }

  function resendCode() {
    console.log("resend call");
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!otp) setOtpErr(true);
    else {
      onValidateOtp();
      // navigate("/set-new-password");
    }
  }

  function onValidateOtp() {
    let object = {
      email: userData ? userData?.email : email,
      code: otp,
    };
    debugger;
    setIsLoading(true);
    validateCode(object)
      .then(({ data }) => {
        setIsLoading(false);
        debugger;
        if (data.error_code == ErrorCodes.success) {
          if (userData) {
            let newUserData = { ...userData };
            newUserData.is_verified = true;
            dispatch(loginAction(newUserData));
            localStorage.setItem(USER_LOGIN_DATA, JSON.stringify(newUserData));
            navigate(routes.welcome);
          } else {
            // localStorage.removeItem("forgetPasswordEmail");
            navigate("/set-new-password", { state: otp });
          }
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  return (
    <div ref={ref} className="signup-container flex flex-col md:flex-row ">
      <div class="left-section w-full md:w-1/2 ">
        <div class="top-div">
          <img
            style={{ objectFit: "contain", width: "100%" }}
            src={forgetPasswordHalfImg}
            alt="Robot Image"
            class="robot-image"
          />
        </div>
        <div class="bottom-div loginWidth">
          <img src={logo} alt="Orbix Logo" class="logo" />
          <h1 className="text-2xl md:text-3xl">Don’t worry it will not take to much time!</h1>
          <p class="text">
            Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus
            id quod maxime placeat facere possimus, omnis voluptas assumenda est,
          </p>
        </div>
      </div>
      <div className="signup-right">
        <form
          className="signup-form"
          style={{ position: "relative", width: "", marginTop: "20px" }}
          onSubmit={handleSubmit}
        >
          <span className="forget_pass_icon">
            <GoVerified size={20} />
          </span>
          <h2 style={{ marginTop: "20px" }}>Verify Code</h2>
          <p>{`We sent the code to the email ending in ${userData ? userData.email : email}`}</p>
          <div className="form-group">
            <VerificationInput
              fields={4}
              autoFocus
              className={"login__container__form__verify"}
              value={otp}
              onChange={(value) => setOtp(value)}
            />
          </div>
          <div className="otp_controls_text">
            <span
              onClick={() => {
                dispatch(loginAction(null));
                localStorage.removeItem(USER_LOGIN_DATA);
                navigate(-1);
              }}
              className="otp_controls_text_back"
            >
              Back
            </span>
            <span onClick={resendCode} className="otp_controls_text_send_again">
              Don't recieve your code?
            </span>
          </div>
          <button
            type="submit"
            className="btn-signup"
            disabled={isLoading}
            style={{ marginTop: "2rem", width: "90%" }}
          >
            {isLoading ? "Processing..." : "Continue"}
          </button>
          <p className="login_signup_text" style={{ alignSelf: "flex-start", marginTop: "2rem" }}>
            <span onClick={() => navigate(routes.signup)}>Create a new account?</span>
          </p>
        </form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}
