import React, { useEffect, useState } from "react";
import PaymentPlan from "../../components/paymentPlan/PaymentPlan";
import { underline } from "../../assets";
import { animateScroll as scroll } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

import "./plans.css";
import { loadStripe } from "@stripe/stripe-js";
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { getClientSecretCall } from "../../hooks/plan";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/AppAction";
import { USER_LOGIN_DATA } from "../../utils/constants";
import { routes } from "../../utils/routes";
import { IoCloseCircle } from "react-icons/io5";
import Loader from "../../components/loading-spinner/Loader";
import { useNavigate } from "react-router-dom";

export default function PlansNew() {
  const { userData } = useSelector((state) => state?.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const stripePromise = loadStripe(
    "pk_test_51O70Z6GcmO69i28vlu2fPm7mntjQaXR9y9ovU8QPddCATWDnZeDU3TE0PIXQxAr4VLlbfcJeDB12w2llS9CUKFyU00vo8ygMcs"
  );
  const [clientSecretData, setClientSecretData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!userData) {
      let user = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
      dispatch(loginAction(user));
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const features2 = [
    "Full Access to Orbi",
    "Personalized Sales Mentor",
    "Detailed Product Insights",
    "Automated Underwriting",
    "Carrier Selection Assistance",
    "Compliance Support",
  ];

  const features3 = [
    "Full Access to Orbi",
    "Team Sales Training",
    "All features of the Starter plan",
    "Priority 24/7 support",
  ];

  const features4 = [
    "Custom Integration Solutions",
    "Unlimited User Access",
    "Dedicated AI Support",
    "Enterprise-Level Underwriting",
    "Strategic Sales Insights",
    "Contact us for a personalized quote",
  ];

  const features1 = [
    "Access to Basic Features",
    "Limited Underwriting Support",
    "Product Guidance",
  ];
  const features5 = [
    "Earn 15% all feature (Agent) commission for each referral that subscribes through your affiliate link. Plus a 21% discount on your monthly subscription.",
  ];

  function getClientSecret(plan_id, price) {
    if (plan_id) {
      let object = {
        user_email: userData.email,
        plan_id: plan_id,
      };
      setIsLoading(true);
      getClientSecretCall(object)
        .then(({ data }) => {
          setIsLoading(false);
          console.log("data", data);
          if (data.error_code == 0) {
            setClientSecretData(data.result);
          } else {
            setClientSecretData(null);
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      <div className={"plane_block"} id="plans">
        <div className={"plans_header"}>
          <div
            className={"plans_header_text"}
            // data-aos="fade-left"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out"
          >
            Plans
          </div>
          <div
            className={"plans_block_under_line"}
            // data-aos="fade-right"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out"
          >
            <img src={underline} className={"plans_block_under_line_img"} />
          </div>
        </div>
        <div className="paymentPlanContainer">
          <div className="responsiveCardsContainer1">
            <div className="paymentPlanContainerChild">
              {" "}
              <PaymentPlan
                align
                // tag
                // tagline="72 hour trial"
                plan="Affiliate"
                price="999.00"
                duration="OneTime Payment"
                cardBtn="Get Started"
                features={features5}
                isHome
                disabled={isLoading}
                onClickGetStarted={() => getClientSecret("667d0612ca6657bebdd86f2b", 999.0)}
              />
            </div>
            <div className="paymentPlanContainerChild">
              <PaymentPlan
                plan="Agency"
                price="499.00"
                duration="Per Month/10 Users"
                features={features3}
                isHome
                disabled={isLoading}
                onClickGetStarted={() => getClientSecret("6659b1cf9bbb09e0e72c5f82", 499.0)}
              />
            </div>
          </div>

          <div className="responsiveCardsContainer2">
            <div className="paymentPlanContainerChild">
              {" "}
              <PaymentPlan
                tag
                tagline="Perfect for Personal use "
                plan="Agent"
                price="49.00"
                duration="Per Month"
                features={features2}
                isHome
                disabled={isLoading}
                onClickGetStarted={() => getClientSecret("667908fa74e357b6063d3b6f", 49.0)}
              />
            </div>
            <div className="paymentPlanContainerChild">
              <PaymentPlan
                plan="Enterprise"
                amountText="Contact us"
                cardBtn="Contact us"
                features={features4}
                isHome
              />
            </div>
          </div>
        </div>
      </div>

      {stripePromise && clientSecretData && (
        <div className="card__main__container">
          <div className="card_container">
            <div className="sub_card_container">
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: clientSecretData.client_secret }}
              >
                <CheckoutForm
                  clientSecretData={clientSecretData}
                  callBack={() => {
                    setClientSecretData(null);
                    if (window.location.pathname == "/subscription") {
                      let newUserData = { ...userData };
                      newUserData.is_user_subscribed = true;
                      dispatch(loginAction(newUserData));
                      localStorage.setItem(USER_LOGIN_DATA, JSON.stringify(newUserData));
                      navigate(routes.dashboard);
                    } else {
                      alert(
                        "Your plan has been updated, you need to login again for changes to be reflected."
                      );
                      dispatch(loginAction(null));
                      localStorage.removeItem(USER_LOGIN_DATA);
                      navigate(routes.login);
                    }
                  }}
                  onClose={() => setClientSecretData(null)}
                />
              </Elements>
            </div>
          </div>
        </div>
      )}
      {isLoading ? <Loader /> : null}
    </>
  );
}

function CheckoutForm({ clientSecretData = null, callBack = () => {}, onClose = () => {} }) {
  const stripe = useStripe();
  const elements = useElements();

  const [selectedCard, setSelectedCard] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (clientSecretData.default_payment_method) {
      let defaultSelectedCard = clientSecretData.stored_payment_methods.find(
        (x) => x.stripe_id == clientSecretData.default_payment_method
      );
      if (defaultSelectedCard) {
        setSelectedCard(defaultSelectedCard);
      }
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("stripe", stripe);
    console.log("elements", elements);
    if (!stripe || !elements) return;

    if (clientSecretData.stored_payment_methods.length > 0) {
      if (!selectedCard) alert("Please select card");
      else {
        setIsLoading(true);
        const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecretData.client_secret,
          {
            payment_method: selectedCard.stripe_id,
          }
        );
        debugger;
        if (confirmError) {
          console.error("Error confirming card payment:", confirmError);
          setIsLoading(false);
        } else {
          console.log("Card payment confirmed successfully:", paymentIntent);
          setIsLoading(false);
          callBack();
        }
      }
    } else {
      let addressElement = elements.getElement(AddressElement);
      const { complete, value } = await addressElement.getValue();
      if (complete) {
        debugger;
        setIsLoading(true);
        if (!clientSecretData.trial_ends_on) {
          const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `${window.location.origin}${routes.dashboard_plans}`,
              payment_method_data: { billing_details: value },
            },
            redirect: "if_required",
          });
          console.log("error", error);
          console.log("paymentIntent", paymentIntent);
          if (error) {
            setMessage(error?.message);
            setIsLoading(false);
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            setIsLoading(false);
            callBack();
          } else {
            setIsLoading(false);
            setMessage("unexpected state.");
          }
        } else {
          const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: `${window.location.origin}${routes.dashboard_plans}`,
              payment_method_data: { billing_details: value },
            },
            redirect: "if_required",
          });
          console.log("error", error);
          if (error) {
            setMessage(error?.message);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            callBack();
          }
        }
      } else {
        alert("Please fill billing address");
      }
    }
  }
  return (
    <form className="flex flex-col p-5 relative" onSubmit={handleSubmit}>
      <IoCloseCircle
        size={27}
        style={{ position: "absolute", top: "-15px", right: "-15px", cursor: "pointer" }}
        onClick={onClose}
      />
      {clientSecretData.stored_payment_methods.length == 0 ? (
        <>
          <PaymentElement />
          <div
            style={{
              borderWidth: 1,
              flex: 1,
              borderColor: "lightgray",
              margin: "10px 0px",
            }}
          />
          <AddressElement options={{ mode: "billing" }} />
        </>
      ) : (
        <div className="grid grid-cols-2 gap-2">
          {clientSecretData.stored_payment_methods.map((item, index) => (
            <div
              className={`flex-1 border border-blue-400 rounded-lg p-2 cursor-pointer ${
                selectedCard?.stripe_id == item.stripe_id && "bg-blue-200"
              }`}
              onClick={() => {
                if (selectedCard?.stripe_id == item.stripe_id) setSelectedCard(null);
                else setSelectedCard(item);
              }}
            >
              <div className="flex justify-between ">
                <h1 className="text-sm font-semibold ">Card:</h1>
                <h1 className="text-sm text-[#637381] text-right max-w-fit">{`**** **** **** ${item.last_digits}`}</h1>
              </div>
              <div className="flex justify-between ">
                <h1 className="text-sm font-semibold "></h1>
                <h1 className="text-sm text-[#637381] text-right max-w-fit">{item.name}</h1>
              </div>
              <div className="flex justify-between">
                <h1 className="text-sm font-semibold "></h1>
                <h1 className="text-sm text-[#637381] text-right w-2/3">{item.line1}</h1>
              </div>
            </div>
          ))}
        </div>
      )}

      {clientSecretData.trial_ends_on ? (
        <span className="text-gray-500 font-medium text-xs my-3">{`You will be charged $${clientSecretData.amount_will_charged} after your trial period ends`}</span>
      ) : null}
      <button type="submit" className="pay_btn" disabled={isLoading}>
        {isLoading ? (
          <LoadingSpinner />
        ) : !clientSecretData.trial_ends_on ? (
          `Pay Now $${clientSecretData.amount_pay_now}`
        ) : (
          `Start Trial Period`
        )}
      </button>
      {message && <span className=" text-red-500 font-medium text-sm my-3">{message}</span>}
    </form>
  );
}
