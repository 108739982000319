import React, { useEffect, useState } from "react";
import "./prompts.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  underline_2,
  promptsvg1,
  promptsvg2,
  promptsvg3,
  promptsvg4,
  promptsvg5,
  promptsvg6,
  promptsvg7,
  promptsvg8,
  promptsvg9,
} from "../../assets";
let prompts = [
  {
    icon: promptsvg1,
    name: "Underwriting",
    points: [
      {
        content:
          "Assessing factors such as medications, BMI, medical conditions, MVR (Motor Vehicle Records), criminal records, and collecting universal demographics.",
      },
    ],
    more: "Read More",
  },
  {
    icon: promptsvg2,
    name: "Carrier choice",
    points: [
      {
        content:
          "Selecting the most suitable insurance provider based on underwriting criteria, ensuring the highest chance of client approval and the best fit for their needs.",
      },
    ],
    more: "Read More",
  },
  {
    icon: promptsvg3,
    name: "Products",
    points: [
      {
        content:
          "Includes Indexed Universal Life (IUL), various riders, Term life insurance, Final Expense coverage, Mortgage Protection plans, Accidental Death and Dismemberment insurance, Whole Life policies, and Graded insurance options.",
      },
    ],
    more: "Read More",
  },
  {
    icon: promptsvg4,
    name: "Sales",
    points: [
      {
        content:
          "Sales strategies involve effectively addressing objections, thoroughly exploring client financial and health details, following a telesales blueprint, and benefiting from personal training by top multi-million dollar annual producing agents.",
      },
    ],
    more: "Read More",
  },
  {
    icon: promptsvg6,
    name: "Laws",
    points: [
      {
        content:
          "Laws encompassing regulations for in-person interactions, compliance with the Telephone Consumer Protection Act (TCPA), adherence to application guidelines, maintaining compliance, and ensuring confidentiality under HIPAA.",
      },
    ],
    more: "Read More",
  },
  {
    icon: promptsvg9,
    name: "Misc",
    points: [
      {
        content: "Not sure what to ask? Just ask!",
      },
    ],
    more: "Read More",
  },
  // {
  //   icon: promptsvg5,
  //   name: "Licensing",
  //   points: [
  //     { content: "Criminal background" },
  //     { content: "Credit" },
  //     { content: "Driving records" },
  //     { content: "LOA guidelines on an entity" },
  //   ],
  //   more: "Read More",
  // },

  // {
  //   icon: promptsvg7,
  //   name: "Tech",
  //   points: [
  //     {
  //       content:
  //         "Contact us for CRM’s, dialers, batching, automations, and efficiency needs to streamline operations",
  //     },
  //   ],
  //   book: "Book a Meeting",
  // },
  // {
  //   icon: promptsvg8,
  //   name: "Marketing",
  //   points: [
  //     {
  //       content:
  //         "Speak to one of the top agents in the country on how to generate quality business",
  //     },
  //   ],
  //   book: "Book a Meeting",
  // },
];
function Prompts() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const handleReadMoreClick = (prompt) => {
    setSelectedPrompt(prompt);
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className={"prompts_blox"} id="features">
      <div
        className={"prompts_blox_header_text"}
        // data-aos="fade-up"
        // data-aos-duration="1000"
        // data-aos-easing="ease-in-out"
      >
        <div className={"prompts_blox_text_1"}>Simplicity is power</div>
        <div className={"prompts_blox_text_1"}>
          6 Essential Prompts for maintaining <br /> expertise in Insurance
        </div>
        <div className={"header_image_under_line_prompt"}>
          <img src={underline_2} alt="img" />
        </div>
      </div>
      <div className={"prompts_bloxs_block"}>
        {prompts.map((prompt, index) => (
          <div className={"prompts_bloxs_block_box"} key={index}>
            <div className={"prompts_boxes_wrapper"}>
              <div className={"prompts_boxes_header"}>
                <div className={"prompts_icon"}>
                  <img src={prompt.icon} alt="img" />
                </div>
                <div className={"prompts_name"}>{prompt.name}</div>
              </div>
              <div className={"prompts_list"}>
                {prompt.points.map((point, pointIndex) => (
                  <div
                    className={"prompts_list_items_wrapper"}
                    key={pointIndex}
                  >
                    <div className={"prompt_point_wrapper"}></div>
                    <div className={"prompts_list_items"} key={pointIndex}>
                      {point.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={"prompt_more_and_book"}>
              {/* {prompt.more && (
                <div
                  className={prompt_more}
                  onClick={() => handleReadMoreClick(prompt)}
                >
                  {prompt.more}
                </div>
              )} */}
              {prompt.book && (
                <div className={"prompt_book"}>{prompt.book}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* {showPopup && selectedPrompt && (
        <Readmore prompt={selectedPrompt} onClose={handlePopupClose} />
      )} */}
    </div>
  );
}

export default Prompts;
