import React, { useEffect, useRef } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Home from "./screens/home/Home.jsx";
import Navbar from "./components/navbar/Navbar.jsx";
import { STRIPE_PUBLISH_KEY, USER_LOGIN_DATA } from "./utils/constants.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Login from "./screens/login/Login.jsx";
import SignUp from "./screens/signup/Signup.jsx";
import ForgetPassword from "./screens/forget-password/ForgetPassword.jsx";
import Otp from "./screens/otp/Otp.jsx";
import NewPassword from "./screens/new-password/NewPassword.jsx";
import PrivacyPolicy from "./screens/privacy-policy/PrivacyPolicy.jsx";
import Help from "./screens/help/Help.jsx";
import FirstWelcome from "./screens/firstWelcome/FirstWelcome.jsx";
import YourRole from "./screens/yourRole/YourRole.jsx";
import HearAboutUs from "./screens/hearAboutUs/HearAboutUs.jsx";
import SelectPlan from "./screens/selectPlan/SelectPlan.jsx";
import SecondWelcomeWithCategory from "./screens/secondWelcomeWithCategory/SecondWelcomeWithCategory.jsx";
import ChatBotMain from "./components/chatBot/ChatBotMain.jsx";
import ChatbotRight_Home from "./components/chatBot/ChatbotRight_Home.jsx";
import ChatbotRight_Communication from "./components/chatBot/ChatbotRight_Comunication.jsx";
import { routes } from "./utils/routes.js";
import SecondWelcome_Billing from "./screens/secondWelcomeWithCategory/SecondWelcome_Billing.jsx";
import SecondWelcome_Emplyee from "./screens/secondWelcomeWithCategory/SecondWelcome_Emplyee.jsx";
import SecondWelcome_Setting from "./screens/secondWelcomeWithCategory/SecondWelcome_Setting.jsx";
import Profile from "./components/Profile.jsx/Profile.jsx";
import Security from "./components/Profile.jsx/Security.jsx";
import Profile_Edit from "./components/Profile.jsx/Profile_Edit.jsx";
import OrbixNavbar from "./components/orbixNavbar/OrbixNavbar.jsx";
import SecondWelcome_Sidebar from "./screens/secondWelcomeWithCategory/SecondWelcome_Sidebar.jsx";
import SecondWelcome_Faq from "./screens/secondWelcomeWithCategory/SecondWelcome_Faq.jsx";
import NavbarNew from "./screens/home/NavbarNew.jsx";
import SecondWelcome_Employee_list from "./screens/secondWelcomeWithCategory/SecondWelcome_Employee_list.jsx";
import SecondWelcome_Add_Employee from "./screens/secondWelcomeWithCategory/SecondWelcome_Add_Employee.jsx";
import PlanScreen from "./screens/PlanScreen.jsx";
import FeatureScreen from "./screens/FeatureScreen.jsx";
import ScrollToTop from "../src/components/ScrollToTop.js";

function App() {
  const Layout = () => {
    return (
      <div>
        <NavbarNew />
        <Outlet />
      </div>
    );
  };

  const DashboardLayout = () => {
    return (
      <div
        //  ref={ref} 
        className="SecondWelcomeWithCategoryMainContainer">
        <div className="fixed top-0 z-10">
          <OrbixNavbar />
        </div>
        {/* <div className="h-5"></div> */}
        <div className="grid grid-cols-12">
          <div className="col-span-1 h-screen fixed top-16 pt-1">
            <SecondWelcome_Sidebar />
          </div>
          {/* <div className="h-5"></div> */}
          <Outlet />
        </div>
      </div>
    );
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} scroll="auto" />
            <Route path={routes.plans} element={<PlanScreen />} />
            <Route path={routes.features} element={<FeatureScreen />} />
          </Route>
          <Route path={routes.privacy_policy} element={<PrivacyPolicy />} />
          <Route path={routes.help} element={<Help />} />
          <Route path={routes.login} element={<Login />} scroll="auto" />
          <Route path={routes.signup} element={<SignUp />} />
          <Route path={routes.forget_password} element={<ForgetPassword />} />
          <Route path={routes.otp} element={<Otp />} />
          <Route path={routes.set_new_password} element={<NewPassword />} />
          <Route path={routes.welcome} element={<FirstWelcome />} />
          <Route path={routes.business_role} element={<YourRole />} />
          <Route path={routes.hear_from} element={<HearAboutUs />} />
          <Route path={routes.subscription} element={<PlanScreen />} />

          {/* Dashboard screens  */}
          <Route path={routes.dashboard} element={<DashboardLayout />}>
            <Route index element={<SecondWelcomeWithCategory />} />
            <Route
              path={routes.dashboard_billing}
              element={<SecondWelcome_Billing />}
            />
            <Route path={routes.dashboard_plans} element={<PlanScreen />} />

            <Route
              path={routes.dashboard_employee}
              element={<SecondWelcome_Emplyee />}
            >
              <Route index element={<SecondWelcome_Employee_list />}></Route>
              <Route
                path={routes.dashboard_employee_list}
                element={<SecondWelcome_Add_Employee />}
              ></Route>
            </Route>
            <Route
              path={routes.dashboard_setting}
              element={<SecondWelcome_Setting />}
            />
            <Route
              path={routes.dashboard_faq}
              element={<SecondWelcome_Faq />}
            />
          </Route>

          <Route path={routes.profile} element={<Profile />}>
            <Route index element={<Profile_Edit />} />
            <Route path={routes.profile_security} element={<Security />} />
          </Route>

          <Route path={routes.chat_bot_main} element={<ChatBotMain />}>
            <Route index element={<ChatbotRight_Home />} />
            <Route
              path={routes.chat_bot_main_chat}
              element={<ChatbotRight_Communication />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
