import React, { useState, useEffect, useRef } from "react";
import "./login.css";
import logo from "../../assets/logo.svg";
import loginHalfImg from "../../assets/loginHalfImg1.svg";
import { useNavigate } from "react-router-dom";
import { login } from "../../hooks/userManagment";
import { ErrorCodes } from "../../utils/enums.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner.jsx";
import { loginAction } from "../../redux/AppAction.js";
import { USER_LOGIN_DATA } from "../../utils/constants.js";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { routes } from "../../utils/routes.js";

export default function Login() {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSecure, setIsSecure] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [PassErr, setPassErr] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "email") setEmailErr(false);
    else if (name === "password") setPassErr(false);
    setState((prev) => ({ ...prev, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!state.email) setEmailErr(true);
    else if (!state.password) setPassErr(true);
    else {
      setIsLoading(true);
      localStorage.removeItem("forgetPasswordEmail");
      login(state)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code === ErrorCodes.success) {
            dispatch(loginAction(data.result));
            localStorage.setItem(USER_LOGIN_DATA, JSON.stringify(data.result));
            if (data.result.is_verified) {
              navigate(
                data.result.is_verified
                  ? data.result.is_user_subscribed
                    ? routes.dashboard
                    : data.result.is_user_business_role && data.result.is_hear_from
                    ? routes.subscription
                    : !data.result.is_user_business_role
                    ? routes.business_role
                    : !data.result.is_hear_from
                    ? routes.hear_from
                    : routes.welcome
                  : "/"
              );
            } else {
              toast.info("Your verification has been generated. Please verify yourself.");
              navigate(routes.otp);
            }
          } else {
            toast.warn(data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Network Error!");
        });
    }
  }
  return (
    <>
      <ToastContainer autoClose={1000} hideProgressBar={true} position="bottom-right" />
      <div className="signup-container flex flex-col md:flex-row" ref={ref}>
        <div class="left-section w-full md:w-1/2">
          <div class="top-div">
            <img
              style={{ objectFit: "contain", width: "100%" }}
              src={loginHalfImg}
              alt="Robot Image"
              class="robot-image"
            />
          </div>
          <div class="bottom-div loginWidth">
            <img src={logo} alt="Orbix Logo" class="logo" />
            <h1 className="text-2xl  md:text-3xl">
              Revolutionizing Insurance with Hyper-Intelligent AI
            </h1>
            <p class="text">
              A Journey Into The Orbix <br /> Skyrocket efficiency, improve customer retention, and
              empower your team!
            </p>
          </div>
        </div>

        <div className="signup-right">
          <form className="signup-form" onSubmit={handleSubmit}>
            <h2>Login</h2>
            <p>Login to your account</p>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                style={{ border: emailErr ? "1px solid red" : "" }}
                type="email"
                id="email"
                name="email"
                value={state.email}
                onChange={handleChange}
                placeholder="Enter your Email"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <label htmlFor="password">Password</label>
              <input
                style={{ border: PassErr ? "1px solid red" : "" }}
                type={isSecure ? "text" : "password"}
                id="password"
                name="password"
                value={state.password}
                onChange={handleChange}
                placeholder="Enter your Password"
                required
                disabled={isLoading}
              />
              <span className="eye_icon " onClick={() => setIsSecure(!isSecure)}>
                {isSecure ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
              </span>
            </div>
            <span className="login_forget_pass" onClick={() => navigate(routes.forget_password)}>
              Forget Password?
            </span>

            <button type="submit" disabled={isLoading} className="btn-signup">
              {isLoading ? <LoadingSpinner /> : "Login"}
            </button>
          </form>
          <p className="login_signup_text">
            Don't have an account?
            <span onClick={() => navigate(routes.signup)}>Sign up</span>
          </p>
        </div>
      </div>
    </>
  );
}
