import React from "react";
import OrbixNavbar from "../../components/orbixNavbar/OrbixNavbar";
import "./FirstWelcome.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Orbixwelcomevideo from "../../assets/Orbixwelcomevideo.mp4";
import { routes } from "../../utils/routes";

export default function FirstWelcome() {
  const navigate = useNavigate("");
  const { userData } = useSelector((state) => state.user);
  console.log("user", userData);

  return (
    <div className="getStartedMainContainer">
      <OrbixNavbar />
      <div className="getStartedMainContainerStarted">
        <div className="videotag">
          <video
            className="orbix_video"
            autoPlay
            loop
            muted
            src={Orbixwelcomevideo}
          ></video>
        </div>
        <div className="getStartedParentContainer">
          <div className="getStartedContainer">
            <h1 className="getStartedHeadingContainer">Welcome to Orbix!</h1>
            <p className="getStartedParaContainer">
              To personalize your experience, please provide us with some
              information about yourself and your objectives.
            </p>
            <button
              onClick={() => {
                navigate(routes.business_role);
              }}
              className="getStartedButton"
            >
              Get Started!
            </button>
          </div>
          <p className="getStartedBottomPara">
            © 2024 Orbix. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
