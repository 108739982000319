import axios from "axios";
import { axiosInstance } from "../axios-client/axiosClient";

export const getAllCategoriesWithChatHeads = async (user_id) => {
  //UPDATED
  let params = {};
  if (user_id) params.user_id = user_id;
  const api = axiosInstance();
  return await api.get("/api/chat-head/get-chat-heads-in-category", { params });
};

export const getChatList = async (chat_head_id) => {
  //get all chat msgs using chat head id
  let params = {};
  if (chat_head_id) params.chat_head_id = chat_head_id;
  const api = axiosInstance();
  return await api.get("/api/chat/get-all-list", { params });
};

export const getAllParentCategories = async (page, limit) => {
  //UPDATED
  let params = {};
  if (page) params.page = page;
  if (limit) params.limit = limit;
  const api = axiosInstance();
  return await api.get("api/category/get-all-categories", { params });
};

export const getAllChildCategories = async (id, page, limit) => {
  let params = {};
  if (id) params._id = id;
  if (page) params.page = page;
  if (limit) params.limit = limit;
  const api = axiosInstance();
  return await api.get("api/category/allSub-category", { params });
};

export const sendMessage = async (data) => {
  const api = axiosInstance();
  return await api.post("api/chat/start-chat", data);
};

export const getAllSubscriptions = async (PlanType) => {
  let params = {};
  if (PlanType) params.planType = PlanType;
  const api = axiosInstance();
  return await api.get("api/plans/get-allPlans", { params });
};

export const postStripePaymentApiCall = async (data) => {
  const api = axiosInstance();
  return await api.post("api/stripe/create-payment", data);
};

export const NewMemberShipApiCall = async (data) => {
  const api = axiosInstance();
  return await api.post("api/memberShip/newMembership", data);
};

export const getAllChatHeadsApiCall = async (category_id, user_id) => {
  //UPDATED
  let params = {};
  if (category_id) params.category_id = category_id;
  if (user_id) params.user_id = user_id;
  const api = axiosInstance();
  return await api.get("api/chat-head/get-all-chat-heads", { params });
};

export const getAllMessagesByChatIdApiCall = async (_id) => {
  //UPDATED
  let params = {};
  if (_id) params._id = _id;
  const api = axiosInstance();
  return await api.get("api/chat-head/get-chat-head", { params });
};

export const deleteChatHeadApiCall = async (data) => {
  const api = axiosInstance();
  return await api.post("api/chat-head/delete", data);
};

export const getAllPaymentHistory = async (_id) => {
  let params = {};
  if (_id) params._id = _id;
  const api = axiosInstance();
  return await api.get("api/category/chatHead/getChatHead", { params });
};

export const mailSubscription = async (data) => {
  const api = axiosInstance();
  return await api.post("api/email-send", data);
};
