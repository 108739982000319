import React, { useEffect, useState, useRef } from "react";
import "./SecondWelcomeWithCategory.css";
import OrbixNavbar from "../../components/orbixNavbar/OrbixNavbar";
import { useNavigate } from "react-router-dom";
import { getAllCategoriesWithChatHeads } from "../../hooks/chat";
import { ErrorCodes } from "../../utils/enums.js";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ExploreCategory from "../../components/exploreCategory/ExploreCategory";
import { useSelector } from "react-redux";
import {
  categoriesDataState,
  chatHeadStatus,
  selectedCatIdState,
  selectedChatIdState,
} from "../../redux/AppAction.js";
import SecondWelcome_Sidebar from "./SecondWelcome_Sidebar";
import { CATEGORY_ID, USER_LOGIN_DATA } from "../../utils/constants.js";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner.jsx";
import { routes } from "../../utils/routes.js";

export default function SecondWelcomeWithCategory() {
  const ref = useRef(null);
  const { userData } = useSelector((state) => state?.user);
  const navigate = useNavigate("");
  const dispatch = useDispatch("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [userName, setUserName] = useState(userData ? userData?.name : "");

  let backgroundColors = [
    "#60b7ff2e",
    "#0393D42e",
    "#FFA0002e",
    "#FFD7E52e",
    "#0571F92e",
    "#4FBA6F2e",
  ];

  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  useEffect(() => {
    let user;
    if (!userData) {
      user = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
      setUserName(user?.name);
      getCategories(user?._id);
    } else getCategories();

    dispatch(selectedChatIdState(null));
    dispatch(chatHeadStatus(null));
  }, []);

  function getCategories(id) {
    setIsLoading(true);
    getAllCategoriesWithChatHeads(userData?._id || id)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCodes.success) {
          dispatch(categoriesDataState(data.result));
          setData(data.result);
        } else {
          toast.warn("Opps! Some error occured");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Network Error!");
      });
  }

  function onClick(id) {
    dispatch(selectedCatIdState(id));
    localStorage.setItem(CATEGORY_ID, id);
    navigate(routes.chat_bot_main_chat);
  }

  return (
    <>
      <ToastContainer
        autoClose={1000}
        hideProgressBar={true}
        position="bottom-right"
      />
      <div
        className="col-span-12 w-screen flex justify-center items-center bg-[#f5f9ffa0]"
        ref={ref}
      >
        <div className="w-11/12 pt-14 pl-14">
          <div className="w-full h-auto xxs:px-7 py-3">
            <div className="w-full min-h-[91vh] h-auto rounded-2xl flex flex-col items-center justify-center">
              <div className="w-full p-6">
                <div className="w-full mb-2">
                  <div className="SecondWelcomeWithCategoryMainContainer">
                    <div className="SecondWelcomeWithCategoryContainer">
                      <h1 className="SecondWelcomeWithCategoryHeadingContainer py-1">
                        Welcome to Orbix
                      </h1>
                      <p className="SecondWelcomeWithCategoryParaContainer pb-5">
                        Good Evening {userName} ⛅
                      </p>
                    </div>
                  </div>
                  <div className="mx-auto grid grid-cols-12 justify-center items-center gap-5 sm:w-[70%]">
                    {isLoading ? (
                      <div className="col-span-12 pt-10">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      data.map((item, index) => (
                        <ExploreCategory
                          id={item?.category?._id}
                          image={item?.category?.logo}
                          title={item?.category?.name}
                          description={item?.category?.description}
                          bgColor={
                            backgroundColors[index]
                              ? backgroundColors[index]
                              : backgroundColors[0]
                          }
                          onClick={onClick}
                        />
                      ))
                    )}
                  </div>
                  <div className="w-full text-center text-xs text-slate-400 pt-10">
                    © 2024 Orbix. All Rights Reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
