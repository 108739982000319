import React from "react";
import bot from "../../assets/bot.svg";
import moment from "moment/moment";

function LeftChat({ content = "",time }) {
  return (
    <div className="py-2">
      <div className="rounded-xl  flex">
        <div className="flex border rounded-xl p-2">
          <div className="chatBot_communi_chat content-center text-start">{content}</div>
          <div className="flex flex-col justify-end items-end chatBot_communi_detail">
            <img className=" h-4" src={bot} alt="" />
            <p className='chatBot_communi_chat_date text-center'> {moment(time).format("MMM-DD")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftChat;
